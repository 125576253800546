html, body {
  font: 16px 'Inter', 'Lucida Sans Unicode', 'Lucida Grande', Arial, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

#api-wrapper {
  width: 100%; /*set to desired width*/
  height: 100%;
  margin: auto;
  position: relative;
}

#api-header {
  height: 57px; /* set to desired height*/
}

#api-header header {
  padding-left: 10px;
  padding-right: 10px;
}

#api-content {
  position: absolute;
  bottom: 0;
  top:57px; /*must match the height of #header*/
  width: 100%;
  overflow: hidden;
}

iframe {
  border: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.cc-theme-edgeless.cc-window .cc-message a {
  color: #fff;
  text-decoration: none;
}

.cc-theme-edgeless.cc-window .cc-message a:hover {
  text-decoration: underline;
}

.cc-revoke {
  display: none !important;
}