html, body {
  font: 16px 'Inter', 'Lucida Sans Unicode', 'Lucida Grande', Arial, sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

#api-wrapper {
  width: 100%; /*set to desired width*/
  height: 100%;
  flex-direction: column;
}

#api-header {
  --tw-shadow: 0 1px 3px 0 var(--tw-shadow-color, #0000001a), 0 1px 2px -1px var(--tw-shadow-color, #0000001a);
  box-shadow: var(--tw-inset-shadow), var(--tw-inset-ring-shadow), var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  z-index: 100;
}

#api-header header {
  padding: 0.625rem;
  position: static;
}

#api-content {
  width: 100%;
  overflow: hidden;
}

iframe {
  border: 0;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.cc-theme-edgeless.cc-window .cc-message a {
  color: #fff;
  text-decoration: none;
}

.cc-theme-edgeless.cc-window .cc-message a:hover {
  text-decoration: underline;
}

.cc-revoke {
  display: none !important;
}